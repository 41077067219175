'use client';

import { PropsWithChildren } from 'react';

import { NextUIProvider } from '@nextui-org/react';
import * as Sentry from '@sentry/nextjs';
import { configureApiClient } from '@salesport/api';
import {
  createServiceWorkerConfig,
  L,
  LoggerWriter,
  sentryLogsWriter,
} from '@salesport/logger';
import { AppQueryClientProvider } from '@salesport/shared/providers';

import { createClient } from '@/common/utils/supabase/client';
import { clientEnv, isProductionBuild } from '@/env/client-env';

initClientLogger();
configureApiClient({
  baseUrl: clientEnv.spApiUrl,
  requestInterceptors: [addAuthHeaderMiddleware],
});

async function addAuthHeaderMiddleware(
  request: RequestInit,
): Promise<RequestInit> {
  const supabaseClient = createClient();
  const { data } = await supabaseClient.auth.getSession();
  if (data.session != null) {
    const headers = new Headers(request.headers);
    headers.set('Authorization', `Bearer ${data.session.access_token}`);
    request.headers = headers;
  }
  return request;
}

export const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <NextUIProvider>
      <AppQueryClientProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </AppQueryClientProvider>
    </NextUIProvider>
  );
};

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="bg-background text-foreground light">{children}</div>;
};

function initClientLogger() {
  const logLevel = clientEnv.logLevel;
  const sentryEnabled = isProductionBuild();
  let sentryWriter: LoggerWriter;
  if (sentryEnabled) {
    sentryWriter = (logEvent) =>
      sentryLogsWriter(Sentry.getCurrentScope(), logEvent);
  }
  const config = createServiceWorkerConfig({
    logLevel,
    serializeErrors: true,
    writer: sentryWriter,
  });

  L.init(config);
}
